import React, { useEffect, useState, useLayoutEffect } from 'react'
import pRetry from 'p-retry'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import PageHead from 'src/components/head/PageHead'
import buildImageObject from 'src/js/utils/buildImgObject'
import { useWindowEvent } from 'src/js/utils/hooks'
import { FIAT_SERVER_URL, FIAT_SERVER_URL_SANDBOX } from 'src/constants'

import 'static/purchase/scss/styles.scss'

const imgWidth = 1021
const imgHeight = 434
const imgRatio = imgWidth / imgHeight

const images = {
  bg: {
    aspectRatio: imgRatio,
    path: '/purchase/images/',
    fileType: 'png',
    files: [
      {
        fileName: 'bg_image',
        width: imgWidth,
      },
    ],
  },
}

const STATUS_TYPES = {
  FAILURE: 'FAILURE',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETE',
  UNRECOGNIZED: 'UNRECOGNIZED',
}

const STATUS_INFO_MAP = {
  // ramp
  CANCELLED: STATUS_TYPES.FAILURE,
  FIAT_RECEIVED: STATUS_TYPES.IN_PROGRESS,
  FIAT_SENT: STATUS_TYPES.IN_PROGRESS,
  INITIALIZED: STATUS_TYPES.IN_PROGRESS,
  PAYMENT_EXECUTED: STATUS_TYPES.IN_PROGRESS,
  PAYMENT_FAILED: STATUS_TYPES.FAILURE,
  PAYMENT_IN_PROGRESS: STATUS_TYPES.IN_PROGRESS,
  PAYMENT_STARTED: STATUS_TYPES.IN_PROGRESS,
  RELEASED: STATUS_TYPES.COMPLETED,
  RELEASING: STATUS_TYPES.IN_PROGRESS,
  // moonpay
  WAITING_PAYMENT: STATUS_TYPES.IN_PROGRESS,
  PENDING: STATUS_TYPES.IN_PROGRESS,
  WAITING_AUTHORIZATION: STATUS_TYPES.IN_PROGRESS,
  FAILED: STATUS_TYPES.FAILURE,
  COMPLETED: STATUS_TYPES.COMPLETED,
  // sardine
  COMPLETE: STATUS_TYPES.COMPLETED,
  DECLINED: STATUS_TYPES.FAILURE,
  DRAFT: STATUS_TYPES.IN_PROGRESS,
  USER_CUSTODY: STATUS_TYPES.IN_PROGRESS,
  PROCESSED: STATUS_TYPES.IN_PROGRESS,
  // ramp and sardine
  EXPIRED: STATUS_TYPES.FAILURE,
}

const getTopPos = (id) => {
  let element = document.getElementById(id)
  let top = element.offsetTop
  while ((element = element.offsetParent)) top += element.offsetTop
  return top
}

const getStatusInfo = (status) => {
  if (!status) return
  return STATUS_INFO_MAP[status] ? STATUS_INFO_MAP[status] : STATUS_TYPES.UNRECOGNIZED
}

const LOAD_STATES = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
  NOT_FOUND: 'not found',
}

const MAX_FETCH_ORDER_RETRIES = 6
const FETCH_ORDER_INTERVAL = 3000

const ERROR_ORDER_NOT_FOUND = {
  code: 404,
  message: 'Order not found',
}

const ContactSupport = ({ text }) => <a href="mailto:support@exodus.com">{text}</a>

const Purchase = ({ location }) => {
  const [loadState, setLoadState] = useState(LOAD_STATES.LOADING)
  const [errDetail, setErrDetail] = useState(null)
  const [details, setDetails] = useState(null)
  const [screenSize, setScreenSize_] = useState({ width: 0, height: 0 })
  const [imgPos, setImgPos] = useState(null)

  const setScreenSize = () => {
    setScreenSize_({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  const getOrder = (orderId, serverURL) => async () => {
    const result = await fetch(`${new URL(`/api/order/${orderId}`, serverURL)}`)
    const body = await result.json()

    if (result.ok) {
      return body
    }

    let error = new Error('Failed to fetch order.')

    if (body?.error?.status === 404) {
      error = new Error(body.error.detail || ERROR_ORDER_NOT_FOUND.message)
      error.code = 404
    }

    error.detail = body?.error?.detail
    throw error
  }

  useEffect(() => {
    setScreenSize()

    const url = new URL(location.href)
    const orderId = url.searchParams.get('orderId')
    const sandbox = url.searchParams.get('sandbox')
    const isSandbox = sandbox && sandbox === 'true'
    const fiatServerBase = isSandbox ? FIAT_SERVER_URL_SANDBOX : FIAT_SERVER_URL

    if (!orderId) {
      return
    }

    const fetchOrderAndUpdatePageState = async (orderId) => {
      try {
        const result = await pRetry(getOrder(orderId, fiatServerBase), {
          minTimeout: FETCH_ORDER_INTERVAL,
          maxTimeout: FETCH_ORDER_INTERVAL,
          retries: MAX_FETCH_ORDER_RETRIES,
          factor: 1,
        })

        setDetails(result)
        setLoadState(LOAD_STATES.SUCCESS)
      } catch (error) {
        console.error('Fiat: failed to fetch order', error, {
          url,
          orderId,
          fiatServerBase,
        })

        if (error.code === 404) {
          setLoadState(LOAD_STATES.NOT_FOUND)
        } else {
          setLoadState(LOAD_STATES.ERROR)
          setErrDetail(error.detail)
        }
      }
    }

    fetchOrderAndUpdatePageState(orderId)
  }, [location])

  useWindowEvent('resize', setScreenSize)

  const isLoading = loadState === LOAD_STATES.LOADING
  const isLoaded = loadState === LOAD_STATES.SUCCESS
  const isNotFound = loadState === LOAD_STATES.NOT_FOUND
  const isError = loadState === LOAD_STATES.ERROR

  const statusInfo = isLoaded ? getStatusInfo(details?.status) : undefined
  const readableStatus = statusInfo?.replaceAll('_', ' ').toLowerCase()

  const isFailedOrder = statusInfo === STATUS_TYPES.FAILURE
  const isSuccessfulOrder =
    statusInfo === STATUS_TYPES.IN_PROGRESS || statusInfo === STATUS_TYPES.COMPLETED
  const isCompletedOrder = statusInfo === STATUS_TYPES.COMPLETED
  const isUnrecognizedOrder = statusInfo === STATUS_TYPES.UNRECOGNIZED
  const isMalformed = isLoaded && !statusInfo

  const isProblem = isError || isUnrecognizedOrder || isMalformed

  const showBg = isSuccessfulOrder || isFailedOrder || isNotFound || isProblem

  useLayoutEffect(() => {
    if (showBg) {
      const position = getTopPos('bottom-text') + 70
      setImgPos(position)
    }
  }, [showBg, screenSize])

  const containerHeight = screenSize.height - imgPos
  const containerWidth = screenSize.width

  let marginAdjust = 0
  const containerRatio = containerWidth / containerHeight
  if (containerRatio < imgRatio) {
    const ratioDiff = containerRatio - imgRatio
    marginAdjust = (ratioDiff * imgWidth) / 2
  }

  return (
    <main className="x-page-purchase">
      <div className="x">
        <div className="x__bg-container">
          <div className="x__bg-blue-gradient" />
          <div className="x__bg-purple-gradient" />
          {showBg && (
            <div
              className="x__bg-img-container"
              style={{
                top: `${imgPos}px`,
                marginLeft: marginAdjust,
                marginRight: marginAdjust,
              }}
            >
              <Img
                imgStyle={{
                  objectPosition: 'bottom',
                  'object-fit': 'contain',
                }}
                fluid={buildImageObject(images.bg)}
                durationFadeIn={1000}
                alt="Blue convolution"
              />
            </div>
          )}
        </div>
        {isLoading && (
          <div className="x-loader__container">
            <div className="x-loader__outer">
              <div className="x-loader__inner">
                <span className="x-loader__spinner" />
              </div>
            </div>
          </div>
        )}
        {isFailedOrder && (
          <div className="x-content__details-container">
            <div className="x-content__icon-error" />
            <div className="x-content__heading">Order Failed</div>
            <div className="x-content__status" id="bottom-text">
              Please check the status of your payment in History
            </div>
          </div>
        )}
        {isSuccessfulOrder && (
          <div className="x-content__details-container">
            <div className="x-content__icon-success" />
            <div className="x-content__heading">Payment {readableStatus}</div>
            <div className="x-content__status" id="bottom-text">
              You paid: {details.fromAmount} {details.fromAsset}
            </div>
            <div className="x-content__status">
              {isCompletedOrder ? 'You received' : "You'll receive"}: {details.toAmount}{' '}
              {details.toAsset}
            </div>
          </div>
        )}
        {isNotFound && (
          <div className="x-content__details-container">
            <div className="x-content__icon-error" />
            <div className="x-content__heading">Payment In Progress</div>
            <div className="x-content__status" id="bottom-text">
              Check your portfolio to see your updated balance.{'\n'}
              <ContactSupport text="Contact support" /> if there is any issue.
            </div>
          </div>
        )}
        {isProblem && (
          <div className="x-content__details-container">
            <div className="x-content__icon-error" />
            <div className="x-content__heading">Problem Fetching Order</div>
            <div className="x-content__status" id="bottom-text">
              {errDetail && `${errDetail}. \n`}
              Please try again or <ContactSupport text="contact support" /> if the issue persists.
            </div>
          </div>
        )}
      </div>
    </main>
  )
}

Purchase.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

Purchase.defaultProps = {}

export default Purchase

// <head> component:
export function Head() {
  return <PageHead page="purchase" noindex />
}
